import { isShopeeApp, isCallByDrawer, isAndroid, isPC } from '../common/util';
import { capture, custom } from '../common/exceptionHandler';
import { formatMsg } from '@/locales';
import { forceHttps, isH5Url, isRnUrl, replaceParam, transformUrl } from '@/module/url';
// import { setLocalItem } from '@/module/storage';
// import { LAST_PAGE_TIME } from '@/common/constant';
// 后续使用 web-bridge-sdk ，如果有比较大的导入文件，考虑单独抽离方法，
// https://git.garena.com/shopee/mall-fe/core-fe-aq/web-bridge-sdk
import {
  initSdk,
  trackBIEvent,
  callHandler,
  registerHandler,
  unregisterHandler as bridgeUnregisterHandler,
  configurePage,
  popWebView,
  getAppInfo as getAppInfoSDK,
  openICCamera2,
  navigate,
  login,
  openExternalLink,
  navigateAppRL as navigateAppRLSDK,
  showToast as showToastSDK,
  getRiskToken,
  getDeviceFingerprint,
  // onEventCallback,
  registerViewWillReappear,
  registerDidTapBack,
} from '@shopee/web-bridge-sdk';
import { getLocalItem, removeItem } from '@/module/storage';

initSdk();
// 有回调的函数，且有传参
const BridgeActionCb = {
  popWebView,
  openICCamera2,
  navigate,
  login,
  openExternalLink,
  navigateAppRL: navigateAppRLSDK,
  showToast: showToastSDK,
};
type TBridgeActionCb = keyof typeof BridgeActionCb;
// 有回调，只有 callback 入参
const BridgeActionCbNoParams = {
  getAppInfo: getAppInfoSDK,
};
type TBridgeActionCbNoParams = keyof typeof BridgeActionCbNoParams;
const BridgeActionNoCb = {
  trackBIEvent,
  configurePage,
};
type TBridgeActionNoCb = keyof typeof BridgeActionNoCb;
// bridge帮忙设置了超时的函数
const BrigeActionTimeout = {
  getRiskToken,
  getDeviceFingerprint,
};
type TBrigeActionTimeout = keyof typeof BrigeActionTimeout;
// 注册事件
const BrigeActionRegister = {
  viewWillReappear: registerViewWillReappear,
  didTapBack: registerDidTapBack,
};
type TBrigeActionRegister = keyof typeof BrigeActionRegister;

// @shopee/web-bridge-sdk 没有直接实现的，可以直接调用 command命令执行
type TDirectCommand =
  | 'getFirebaseAppInstanceID'
  | 'previewFile'
  | 'save'
  | 'load'
  | 'showPopUp'
  | 'pickImage'
  | 'fullScreenImage'
  | 'copyTextToClipboard'
  | 'enableRefreshOnWebViewBlankWhenViewDidAppear'
  | 'requestAuthCodeFromCoreAuth'
  | 'requestAuthToken'
  | 'onEventCallback'
  | 'showSharingPanel'
  | 'shareData'
  | 'getAccountInfo'
  | 'getData';

const needTimeoutEvent: Record<string, number> = {
  getAppInfo: 1,
  getRiskToken: 1,
  getDeviceFingerprint: 1,
  getFirebaseAppInstanceID: 1,
};

export async function call(
  action:
    | TBridgeActionCb
    | TBridgeActionCbNoParams
    | TBridgeActionNoCb
    | TBrigeActionTimeout
    | TBrigeActionRegister
    | TDirectCommand,
  srcParams: any = {},
  once?: boolean,
): Promise<any> {
  let navigateUrl = srcParams.apprl || srcParams.url;
  // 跳转页面，记录下上一跳页面
  if ((action == 'navigate' || action == 'navigateAppRL') && navigateUrl) {
    if (navigateUrl.indexOf(location.host) >= 0) {
      const m = location.pathname.match(/\/([^/]*)\.html$/);
      if (m && m[1]) {
        navigateUrl = replaceParam('pp', m[1], navigateUrl);
      }
    } else {
      navigateUrl = replaceParam(
        'pp',
        `${location.protocol}//${location.host}${location.pathname}`,
        navigateUrl,
      );
    }
    srcParams[action == 'navigateAppRL' ? 'apprl' : 'url'] = navigateUrl;

    if (!isShopeeApp) {
      // eslint-disable-next-line no-alert
      location.href = navigateUrl;
    }
  }
  if (process.env.REACT_APP_ENV != 'live') {
    console.log('bridgeCallHandler', action, srcParams);
  }
  let timeout: any = 0;
  return Promise.race([
    new Promise((resolve) => {
      const time = needTimeoutEvent[action];
      if (time) {
        timeout = setTimeout(
          () => {
            console.warn('jsbridge_timeout', { code: action });
            resolve({});
          },
          time < 4 ? 5000 : time,
        );
      }
    }),
    new Promise((resolve) => {
      // 没回调的 event
      if (BridgeActionNoCb[action as TBridgeActionNoCb]) {
        resolve(BridgeActionNoCb[action as TBridgeActionNoCb](srcParams));
        return;
      }
      if (BridgeActionCbNoParams[action as TBridgeActionCbNoParams]) {
        BridgeActionCbNoParams[action as TBridgeActionCbNoParams](resolve);
        return;
      }
      // 有回调有入参的 event
      if (BridgeActionCb[action as TBridgeActionCb]) {
        BridgeActionCb[action as TBridgeActionCb](srcParams, resolve);
        return;
      }
      // 注册事件的 event
      if (BrigeActionRegister[action as TBrigeActionRegister]) {
        const res = BrigeActionRegister[action as TBrigeActionRegister]((...args) => {
          if (typeof srcParams == 'function') {
            srcParams(...args);
          }
          if (once) {
            if (typeof res == 'string') {
              bridgeUnregisterHandler(action as any, res);
            } else {
              res.unregister && res.unregister();
            }
          }
        });
        resolve(res);
        return;
      }
      // 有超时的 event
      if (BrigeActionTimeout[action as TBrigeActionTimeout]) {
        BrigeActionTimeout[action as TBrigeActionTimeout]({ timeout: 5000 }).then((res: any) => {
          resolve(res);
          if (res && res.error == 1) {
            console.warn('jsbridge_timeout', { code: action, res });
          }
        });
        return;
      }
      // 通用兜底，如果参数是函数，就认为是注册事件
      // https://git.garena.com/shopee/mall-fe/core-fe-aq/web-bridge-sdk/-/blob/master/src/core/index.ts
      if (typeof srcParams == 'function') {
        const res = registerHandler(action as any, (...args) => {
          srcParams(...args);
          if (once && typeof res == 'string') {
            bridgeUnregisterHandler(action as any, res);
          }
        });
        resolve(res);
      } else {
        callHandler(action as any, srcParams, resolve);
      }
    }),
  ]).then((result) => {
    clearTimeout(timeout);
    return result;
  });
}
export function unregisterHandler(callback: Promise<any>, name?: string) {
  if (!callback) return;
  try {
    callback.then((res) => {
      if (!res) return;
      if (typeof res == 'string') {
        bridgeUnregisterHandler(name as any, res);
        return;
      }
      res.unregister && res.unregister();
    });
  } catch (error) {
    console.error('unregisterHandler', name, error);
  }
}

const tabMap = {
  product: 'products',
};

export const PolicyTabTypeMap = {
  'To Pay': 1,
  Purchased: 2,
  Expired: 3,
  Cancelled: 4,
};

export async function switchToH5IfCfg(apprl: string, params?: any) {
  if (!apprl.includes('rn/@shopee-rn/insurance-market/HOME')) return;
  // const cfg = await getGlobalConfig();
  // if (!+cfg.switchToH5) return;

  const { tab, policyTab, ...search } = params || {};
  search.tab = (tabMap as any)[tab] || tab;
  if (search.tab === 'policies') {
    search.panel = 'policy';
    search.status = (PolicyTabTypeMap as any)[policyTab];
  }
  const jumpParam: Record<string, any> = { search };
  if (search.__pc__ > 0) {
    jumpParam.ins_level = 0;
  }
  return {
    apprl: transformUrl(jumpParam, '/common/home.html'),
    params: search,
  };
}

/**
 * @param url
 * @param params  __pc__:pop count __anim__:animation type
 */
export async function navigateAppRL(apprl: string, params?: object) {
  try {
    const isSwitch = await switchToH5IfCfg(apprl, params);
    if (isSwitch) {
      apprl = isSwitch.apprl;
      params = isSwitch.params;
    }
  } catch (error) {
    console.error('navigateAppRL_switchToH5IfCfg_error', error);
  }
  call('navigateAppRL', {
    apprl,
    params: params || {},
    config: {
      // IOS ONLY
      disableBounce: 1,
      // IOS ONLY
      // disableNativeLoadingView: 1,
    },
  });
}

export interface ICallWebviewData {
  url: string;
  title?: string;
  popSelf?: 1 | 0;
  backUrl?: string;
  type?: 'ins' | 'external';
  navbar?: Object;
}
export async function callWebview(data: ICallWebviewData): Promise<void> {
  const { title, popSelf = 0, backUrl, navbar = {} } = data;

  let url = (data.url || '').trim();
  if (!isRnUrl(url) && !isH5Url(url)) {
    url = `${location.protocol}//${location.host}${url.indexOf('/') == 0 ? url : `/${url}`}`;
  }
  // const url =
  //   (process.env.REACT_APP_ENV === 'test' || process.env.REACT_APP_ENV === 'mock') &&
  //   getUrlParamByKey('debug') === '1'
  //     ? data.url.replace('/ins/', '/')
  //     : data.url;
  if (isCallByDrawer) {
    const msg = {
      cmd: 'navigate',
      data: url,
    };
    window.postMessage(JSON.stringify(msg));
    return;
  }

  if (window.location.pathname === '/ins/payment-result.html') {
    if (!isAndroid() || (await getLocalItem('__CHECKOUT_ORDER_CHANNEL')) !== 'shopeePay') {
      getLocalItem('__INSURANCE_PAGES_COUNT__').then((ins_level) => {
        console.warn('ins_level', { ins_level });
        removeItem('__INSURANCE_PAGES_COUNT__', 'local');
        navigateAppRL(url, {
          __pc__: +ins_level || 1,
        });
      });
      return;
    }
  }

  call('navigate', {
    url,
    navbar: {
      isTransparent: 0,
      navbarStyle: 0,
      title: title || '',
      ...navbar,
    },
    config: {
      // IOS ONLY
      disableBounce: 1,
      // IOS ONLY
      // disableNativeLoadingView: 1,
    },
    popSelf,
    backUrl,
  });
}
export function callWebviewUrl(url?: string, data?: Partial<ICallWebviewData>) {
  const par = data || {};
  if (!url) return;
  callWebview({
    url,
    ...par,
  });
}

/**
 * 根据 url 来区分是调用 H5 还是 RN , 不穿 url ，默认是当前 url
 * @param params 需要修改的url值，有： pathname,search,hash
 * @param url
 * @returns
 */
export function callNavigate(params?: Record<string, any>, url?: string): void {
  if (!url && (!params || Object.keys(params).length == 0)) {
    return;
  }
  if (!url || !isRnUrl(url)) {
    callWebviewUrl(transformUrl(params, url));
    return;
  }
  navigateAppRL(url, params);
}

// 支持 /ins/xxx.html
export function callNavigateUrl(params?: Record<string, any>, url?: string): void {
  if (!url) return;
  // if (!/^(((http(s)?:)?\/\/)|(rn\/))/.test(url)) {
  //   url = `${location.protocol}//${location.host}${url}`;
  // }
  callNavigate({ search: params }, url);
}

export function callNativePage(config: object, popSelf: boolean = false): void {
  window?.BJUtil?.navigateReactNative?.(config, popSelf);
}

export function getDigitalSignature() {
  if (isAndroid()) {
    return call('getDeviceFingerprint');
  }
  return call('getRiskToken');
}

let appInfo: any;
export function getAppInfo(): Promise<any> {
  return new Promise((resolve) => {
    if (appInfo && appInfo.appVersion) return resolve(appInfo);
    call('getAppInfo')
      .then((res: any) => {
        appInfo = res;
        resolve(res || {});
      })
      .catch(() => {
        resolve({});
      });
  });
}

export function showPopUp(
  popUp: {
    title?: string;
    message?: string;
    okText?: string;
    iconUri?: string;
    cancelText?: string;
    autoDismiss?: boolean;
  } = {},
): Promise<{
  buttonClicked: 0 | 1 | 2; // 0:ok 1: cancel 2: 遮罩
}> {
  if (!isShopeeApp) {
    // eslint-disable-next-line no-alert
    alert(popUp.message);
  }
  return call('showPopUp', {
    popUp: {
      title: popUp.title || undefined,
      message: popUp.message || undefined,
      okText: popUp.okText || formatMsg('OK'),
      iconUri: popUp.iconUri || undefined,
      cancelText: popUp.cancelText || undefined,
      autoDismiss: popUp.autoDismiss || true,
    },
  });
}
export function showToast(message: string, iconType?: string) {
  if (!isShopeeApp) {
    // eslint-disable-next-line no-alert
    alert(message);
  }
  call('showToast', {
    toast: {
      message: message || '',
      iconType: iconType || 'failure',
    },
  });
}

export async function getAuthCode() {
  if (isPC() || !isShopeeApp) {
    return '';
  }

  const res = await call('requestAuthCodeFromCoreAuth', {
    clientID: 'shopee-insurance',
  }); // console.log(res, 'res')

  if (res.errorCode === 0) {
    return res.authCode;
  }

  capture({
    name: 'getAuthCodeFail',
    message: 'getAuthCodeFail',
    data: {
      data_field: {
        code: res.errorCode,
      },
    },
  });
  return '';
}

export function popWebview(params?: { data?: string; count?: number }): void {
  call('popWebView', {
    data: params?.data,
    count: params?.count || 1,
  });
}

export function callPreviewFile(url: string, page_title: string) {
  call('previewFile', {
    url,
    fileName: `${page_title || formatMsg('policydetail.viewepolicy')}.pdf`,
  }).then((res: any = {}) => {
    custom('preview_pdf', {
      code: res.error,
      msg: res.errorMessage,
    });
  });
}
export function callLinkReplace(url: string) {
  url = forceHttps(url);
  if (!url || !isH5Url(url)) {
    console.error('callLinkReplace', url);
    return;
  }
  window.isJumpingLock = true;
  window.location.replace(url);
}
